<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- user list -->
            <section class="published-jobs section-gap-30">
                <!-- Job Search -->
                <JobSearch @searched="searchJobs"></JobSearch>

                <!--Page Title-->
                <div class="section-title-wrap">
                    <h2 class="section-title text-capitalize">{{ $t(`archived jobs`) }}</h2>
                </div>
                <div class="job-sections-wowax" v-if="isContentLoading">
                    <h4 class="empty-message" v-if="isEmpty">{{ $t(`No Archived Jobs Found`) }}</h4>

                    <div class="row row-cols-1 row-cols-lg-2" v-else>
                        <div class="col" v-for="job in archivedJobs">
                            <JobCard @jobDeleted="jobDeleted" :job="job" :options="options"></JobCard>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <infinite-loading v-if="totalJob > 6" :identifier="infiniteKey" spinner="waveDots" @infinite="loadJobPosts">
                                <div slot="no-more"></div>
                                <div slot="no-results"></div>
                            </infinite-loading>
                        </div>
                    </div>
                </div>

                <div class="job-sections-wowax" v-else>
                    <div class="row row-cols-1 row-cols-lg-2">
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                    </div>
                </div>
                <!--Pagination-->
                <!--                <Pagination v-show="!isEmpty"></Pagination>-->

            </section>
        </div>
    </dashboard-layout>
</template>
<script>

import InfiniteLoading from "vue-infinite-loading";
const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
const JobSearch = () => import("../../components/jobs/JobSearch.vue");
const JobCard = () => import("../../components/jobs/JobCard.vue");
const Pagination = () => import("../../components/common/Pagination.vue");
const JobCardLoader = () => import("../../components/_loaders/JobCardLoader.vue");
import client from '../../app/api/Client';


export default {
    components: {
        DashboardLayout,
        JobSearch,
        JobCard,
        Pagination,
        JobCardLoader,
        InfiniteLoading
    },
    data() {
        return {
            isContentLoading: false,
            archivedJobs: [],
            options: {
                pipeline: true,
                candidates: false,
                delete: true,
                edit: true,
                duplicate: true,
                more: false,
                moreView: false,
                moreShare: false,
                moreDuplicate: false,
            },

            page: 2,
            lastPage: null,
            infiniteKey: new Date(),
            form: {
                search: ''
            },
            totalJob: null
        }
    },
    computed: {
        isEmpty() {
            return this.archivedJobs.length === 0;
        }
    },
    methods: {
        async loadArchivedJobs() {
            this.isContentLoading = false;

            let {data: {data}} = await client().get('/job/archived');
            this.archivedJobs = data.data;
            this.lastPage = data.last_page;
            this.totalJob = data.total;

            this.isContentLoading = true;
        },

        async loadJobPosts($state) {
            try {
                let queryParams = {params: {page: this.page, ...this.form}};

                let {data: {data}} = await client().get('/job/archived', queryParams);
                this.page = data.current_page+1;
                this.lastPage = data.last_page;

                this.archivedJobs.push(...data.data);
            } catch (e) {

            }

            $state.loaded();
            if (this.page > this.lastPage) {
                $state.complete();
            }
        },

        async jobDeleted(job) {
            this.archivedJobs = this.archivedJobs.filter(aj => aj.id !== job.id);
        },

        async searchJobs(form) {
            let queryParams = {params: {page: 1, search: form.search}};

            let {data: {data}} = await client().get('/job/archived', queryParams);
            this.page = data.current_page+1;
            this.lastPage = data.last_page;
            this.totalJob = data.total;
            this.infiniteKey = new Date();

            this.archivedJobs = data.data;
        }
    },
    async created() {
        await this.loadArchivedJobs();
    }
}
</script>

<style>
.dropdown-menu.show {
    z-index: 985 !important;
}
</style>
